/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.scss');

const $ = require('jquery');
require('bootstrap');

const TeamPlanChoiceWidget = require('./component/TeamPlanChoiceWidget');

var deleteRowButtons = document.querySelectorAll(".remove-row");

for(var i = 0; i < deleteRowButtons.length; i++) {
    deleteRowButtons[i].addEventListener("click", function (e) {
        e.preventDefault();

        var form = $(this).closest("form");

        $(this).closest("tr").remove();
        form.submit();

        return false;
    })
}

var teamPlanChoiceWidgets = document.querySelectorAll(".team-plan-choice-widget");


for(i = 0; i < teamPlanChoiceWidgets.length; i++) {
    new TeamPlanChoiceWidget(teamPlanChoiceWidgets[i]);
}

