class TeamPlanChoiceWidget {
    constructor(element) {
        this.element = element;

        this.options = [];

        var optionElements = this.element.querySelectorAll(".plan-option");

        for(var i = 0; i <optionElements.length; i++) {
            this.options.push(optionElements[i]);
        }

        this.inputElement = this.element.querySelector('input[type="hidden"]');

        this.setOptionById(this.options[0].getAttribute("data-id"));

        this.initializeEventListeners();
    }

    setOptionById(id) {
        for (var i = 0; i < this.options.length; i++) {
            if(this.options[i].getAttribute("data-id") === id) {
                this.inputElement.value = id;
                this.options[i].classList.remove("btn-outline-primary");
                this.options[i].classList.add("btn-primary");
                continue;
            }

            this.options[i].classList.add("btn-outline-primary");
            this.options[i].classList.remove("btn-primary");
        }
    }

    initializeEventListeners() {
        var self = this;

        for (var i = 0; i < this.options.length; i++) {
            let id = this.options[i].getAttribute("data-id");

            this.options[i].addEventListener("click", function () {
                self.setOptionById(id);
            });
        }
    }
}

module.exports = TeamPlanChoiceWidget;